import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		username: null,
		nightMode: false,
		isUserOnMobile: false,
		fetchTotalsTimeout: null,
		userTotals: null, // {} // setting this to object breaks reactivity
		activeSessions: 0,
	},
	mutations: {
		setUsername(state, username){

			localStorage.removeItem('username') //We only want one login token per computer
			localStorage.setItem('username', username)
			state.username = username
		},
		destroyLoginToken(state){

			//Remove login token from local storage and from headers
			localStorage.removeItem('loginToken')
			localStorage.removeItem('username')
			localStorage.removeItem('currentVersion')
			localStorage.removeItem('snippetCache')
			delete axios.defaults.headers.common['authorizationtoken']
			state.username = null
			state.userTotals = null
		},
		toggleNightMode(state, pastTheme){

			const themes = {
				'white':{
					'body_bg_color': '#f1f1f1',//'#f5f6f7',
					'small_element_bg_color': '#fff',
					'text_color': '#3d3d3d',
					'dark_border_color': '#d9d9d9',//'#DFE1E6',
					'border_color': '#DFE1E6',
					'menu-accent': '#cecece',
					'menu-text': '#5e6268',
				},
				'black':{
					'body_bg_color': 'rgb(12 4 30)',
					//'#0f0f0f',//'#000',
					'small_element_bg_color': '#000',
					'text_color': '#FFF',
					'dark_border_color': '#555',//'#ACACAC', //Lighter color to accent elemnts user can interact with
					'border_color': '#505050',
					'menu-accent': '#626262',
					'menu-text': '#d9d9d9',
				},
			}

			//Catch values not in set

			const totalThemes = Object.keys(themes).length
			state.nightMode++
			if(state.nightMode > totalThemes-1){
				state.nightMode = 0
			}
			if(pastTheme != null){
				state.nightMode = pastTheme
			}

			//Final catch for numbers
			if(Number.isInteger(parseInt(state.nightMode)) == false){
				state.nightMode = 0
			}

			const currentTheme = Object.keys(themes)[state.nightMode]

			//Toggle state and save to local storage
			localStorage.setItem('nightMode', state.nightMode)

			//Go through each color and set CSS variable
			let root = document.documentElement
			Object.keys( themes[currentTheme] ).forEach( attribute => {
				root.style.setProperty('--'+attribute, themes[currentTheme][attribute])
			})

		},
		detectIsUserOnMobile(state){

			//Script from http://detectmobilebrowsers.com
			//Create closure and pass in browser data and state
  			(function(a, state){
  				if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
  				{ 	
  					state.isUserOnMobile = true
  				}
  			})(navigator.userAgent||navigator.vendor||window.opera, state);
		},
		setSocketIoSocket(state, socket){

			//Put socket id in axios headers
			axios.defaults.headers.common['socketId'] = socket
			state.socket = socket
		},
		setUserTotals(state, totalsObject){

			if(!state.userTotals){
				state.userTotals = {}
			}

			// retain old values loaded on initial, extended options load
			let oldMissingValues = {}
			Object.keys(state.userTotals).forEach(key => {
				if(!totalsObject[key] && totalsObject[key] !== 0){
					oldMissingValues[key] = state.userTotals[key]
				}
			})

			// combine old settings with updated settings
			let oldAndNew = Object.assign(oldMissingValues, totalsObject)

			state.userTotals = oldAndNew

			//Set computer version from server
			const currentVersion = localStorage.getItem('currentVersion')
			if(currentVersion == null){
				localStorage.setItem('currentVersion', totalsObject.currentVersion)
				return
			}

			//If version is already set and it doesn't match the server, reload app
			if(currentVersion != totalsObject.currentVersion){
				localStorage.setItem('currentVersion', totalsObject.currentVersion)
				location.reload(true)
			}
			

			// console.log('-------------')
			// Object.keys(totalsObject).forEach( key => {
			// 	console.log(key + ' -- ' + totalsObject[key])
			// })
		},
		setActiveSessions(state, countData){
			//Count of the number of active socket.io sessions for this user
			state.activeSessions = countData
		},
		hideMetricTrackingReminder(state){
			if(state.userTotals){
				state.userTotals['showTrackMetricsButton'] = false
			}
		}
	},
	getters: {
		getUsername: state => {
			return state.username
		},
		getLoggedIn: state => {
			let weIn = (state.username && state.username.length > 0)
			return weIn
		},
		getIsNightMode: state => {
			return state.nightMode
		},
		getIsUserOnMobile: state => {
			return state.isUserOnMobile
		},
		getIsNoteSettingsOpen: state => {
			return state.isNoteSettingsOpen
		},
		getSocket: state => {
			return state.socket
		},
		totals: state => {
			return state.userTotals
		},
		getActiveSessions: state => {
			return state.activeSessions
		}
	},
	actions: {
		fetchAndUpdateUserTotals ({ commit, state }) {
			clearTimeout(state.fetchTotalsTimeout)
			state.fetchTotalsTimeout = setTimeout(() => {
				// load extended options on initial load
				let postData = {
					extendedOptions: !state.userTotals
				}
				axios.post('/api/user/totals', postData)
				.then( ({data}) => {
					commit('setUserTotals', data)
				})
				.catch( error => {
					if(error.response && error.response.status == 400){
						commit('destroyLoginToken')
						location.reload()
					}
				})
			}, 100)
		}
	}
})