import Vue from 'vue'

const helpers = {}

helpers.timeAgo = (time) => {

	if(time == null){
		time = Math.round(time/1000)
	}

	if(time.toString().length >= 13){
		time = Math.round(time/1000)
	}

	const time_formats = [
		[ 60, 			'seconds', 			1						],
		[ 120, 			'1 minute ago', 	'1 minute from now'		],
		[ 3600, 		'minutes', 			60						],
		[ 7200, 		'1 hour ago', 		'1 hour from now'		], 
		[ 86400, 		'hours', 			3600					], 
		[ 172800, 		'yesterday', 		'tomorrow'				],
		[ 604800, 		'days', 			86400					],
		[ 1209600, 		'last week', 		'next week'				],
		[ 2419200, 		'weeks', 			604800					],
		[ 4838400, 		'last month', 		'next month'			],
		[ 29030400, 	'months', 			2419200					],
		[ 58060800, 	'last year', 		'next year'				],
		[ 2903040000, 	'years', 			29030400				],
		[ 5806080000, 	'last century', 	'next century'			],
		[ 58060800000, 	'centuries', 		2903040000				]
	]

	//How many seconds ago was input event time?
	let seconds = Math.round((+new Date)/1000) - time
	let token = 'ago'
	let list_choice = 1
	
	if (seconds == 0) {
		return 'Just now'
	}

	if (seconds < 0) {
		seconds = Math.abs(seconds)
		token = 'from now'
		list_choice = 2
	}
	

	let i = 0
	let format = null
	while (format = time_formats[i++]){
		if (seconds < format[0]) {
			if (typeof format[2] == 'string') {
				return format[list_choice]
			} else {
				return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
			}
		}
	}

	return time
}

const plugin = {
    install () {
        Vue.helpers = helpers
        Vue.prototype.$helpers = helpers
    }
}

Vue.use(plugin)