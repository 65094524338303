<style type="text/css" scoped>
	
	.popup-body {
		position: fixed;
		top: 15px;
		left: 15px;
		min-height: 50px;
		min-width: 200px;
		max-width: calc(100% - 30px);
		z-index: 1020;

		box-shadow: 0px 0px 5px 2px rgba(140,140,140,1);
		border-radius: 4px;

		color: white;
		background-color: var(--main-accent);
	}
	.popup-row {
		padding: 1em 5px;
		cursor: pointer;
	}
	.popup-row > p {
		/*width: calc(100% - 50px);*/
		display: inline-block;
		text-align: left;
		box-sizing: border-box;
		padding: 0 10px 0;
		font-size: 1.25em;
		border-radius: 4px;
	}
	.popup-row + .popup-row {
		border-top: 1px solid #FFF;
	}

	.slide-in-bottom {
		animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	}
	@keyframes slide-in-bottom {
		0% {
			transform: translateY(-1000px);
		}
		100% {
			transform: translateY(0);
		}
	}

	.color-fade {
		animation: color-fade-animation 0.7s ease-out both;
	}
	@keyframes color-fade-animation {
		0% {
			color: black;
			background-color: black;
		}
		50% {
			color: black;
			background-color: black;
		}
		100% {
			color: white;
			background-color: #21ba45;
		}
	}

	.meter { 
		height: 2px;
		display: inline-block;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		overflow: hidden;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	.meter span {
		display: block;
		height: 100%;
	}

	.progress {
		background-color: white;
		animation: progressBar 3s linear;
		animation-fill-mode: both;
	}
	.time-display {
		display: inline-block;
		width: calc(100% - 25px);
		/*text-align: right;*/
		color: white;
		font-size: 0.7em;
		margin: 0 0 0 25px;
	}
	.text-display {
		display: inline-block;
		width: 100%;
	}

	@keyframes progressBar {
		0% { width: 0; }
		100% { width: 100%; }
	}



</style>

<template>
	<div class="popup-body slide-in-bottom" v-on:click="dismiss" v-if="notifications.length > 0">
		<div class="popup-row" v-for="item in notifications">
			<div class="meter">
				<span><span class="progress"></span></span>
			</div>
			<p class="text-display">
				<i class="small info circle icon"></i>
				{{ item.text }}
				<span class="time-display">{{ item.time }}</span>
			</p>
		</div>
	</div>
</template>

<script>

	import axios from 'axios'

	export default {
		name: 'GlobalNotificationComponent',
		data () {
			return {
				notifications: [],
				totalTimeout: null,
			}
		},
		beforeMount(){
			this.$bus.$on('notification', notificationText => {
				this.displayNotification(notificationText)
			})
		},
		mounted(){
			
			// this.$bus.$emit('notification', 'Password Protection Removed Login did not succeed')
			// this.$bus.$emit('notification', 'Password Protection Removed your life is exposed to the internet')
			// this.$bus.$emit('notification', 'Password Protection Removed everyone can see everything')	

		},
		methods: {
			displayNotification(notificationText){

				const date = new Date()
				const time = date.toLocaleTimeString()

				const notification = {
					text: notificationText,
					time: time
				}

				this.notifications.unshift(notification)
				clearTimeout(this.totalTimeout)
				this.totalTimeout = setTimeout(() => {
					this.dismiss()
				}, 3000)
			},
			dismiss(){
				this.notifications = []
			}
		}
	}
</script>
