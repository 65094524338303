// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'

import Vuex from 'vuex'
import store from './stores/mainStore';

import App from './App'
import router from './router'

//Include entire fomantic ui library
// import 'fomantic-ui-css/semantic.css';

//Required site and reset CSS
import 'fomantic-ui-css/components/reset.min.css'
import 'fomantic-ui-css/components/site.css' //modified to remove included LATO fonts

//Only include parts that are used
import 'fomantic-ui-css/components/button.min.css'
import 'fomantic-ui-css/components/container.min.css'
import 'fomantic-ui-css/components/form.min.css'
import 'fomantic-ui-css/components/grid.min.css'
import 'fomantic-ui-css/components/header.min.css'
import 'fomantic-ui-css/components/icon.css' //Modified to remove brand icons
import 'fomantic-ui-css/components/input.min.css'
import 'fomantic-ui-css/components/segment.min.css'
import 'fomantic-ui-css/components/label.min.css'


//Overwrite and site styles and themes and good stuff
require('./assets/semantic-helper.css')
// Fonts 
require('./assets/roboto-latin.woff2')
require('./assets/roboto-latin-bold.woff2')




//Import socket io, init using nginx configured socket path
import io from 'socket.io-client';
const socket = io({ path:'/socket' });

//integrate connected socket into vue instance
Object.defineProperties(Vue.prototype, {
	$io: {
		get: () => socket
	}
})



// This callback runs before every route change, including on page load.
// Sets the title of the page using vue router
router.beforeEach((to, from, next) => {
	document.title = to.meta.title + ' - Solid Scribe';
	next();
});

//Attach event bus to main vue object, all components will inherit event bus
import EventBus from './EventBus'
import Helpers from './Helpers'

Vue.use(Vuex)
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
