<style type="text/css" scoped>
	.fixed-search {
		position: fixed;
		top: 50%;
		left: 0;
		right: 0;
		padding: 10px;
	}
	.floating-button {
		position: absolute;
		right: 7px;
		top: 5px;
		z-index: 2;
	}
	.floating-note-options {
		position: absolute;
		right: 0;
		left: 0;
		top: 35px;
		z-index: 2;
	}
	.floating-note-options > .segment {
		border-top: none;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
</style>
<template>
	<span>

		<div class="ui form" v-on:mouseenter="extraMenuHover = true" v-on:mouseleave="extraMenuHover = false">
			<!-- normal search menu  -->
			<div class="ui left icon fluid input">
				<input ref="desktopSearch" v-on:blur="focused = false" v-on:focus="focused = true" v-model="searchTerm" @keydown="onKeyDown" @keyup="onKeyUp" placeholder="Search or Start Typing New Note" />
				<i class="search icon"></i>
			</div>

			<div class="floating-button" v-if="searchTerm.length > 0">
				<i class="big link grey close icon" v-on:click="clear()"></i>
			</div>


			<div class="floating-note-options"
				v-if="(searchTerm.length > 0 || tagSuggestions.length > 0) && (extraMenuHover)">
				<div class="ui segment">
					<div class="ui very compact grid">
							<div class="five wide column">
								<div class="ui mini green fluid compact shrinking button" v-on:click="search()">
									<i class="search icon"></i>Search
								</div>
								<span class="button-sub">
									( Enter )
								</span>
							</div>
							<div class="five wide middle aligned column">
								<div class="ui mini green fluid compact shrinking button" v-on:click="pushToNewNote()">
									<i class="plus icon"></i>A New Note
								</div>
								<span class="button-sub">
									( Tab )
								</span>
							</div>
							<div class="six wide right aligned column">
								<div class="ui mini green fluid compact shrinking button" v-on:click="pushToQuickNote()">
									<i class="sticky note outline icon"></i>The Scratch Pad
								</div>
								<span class="button-sub">
									( CTRL + ENTER )
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

	</span>
</template>

<script>

	import axios from 'axios'

	export default {

		data: function(){ 
			return {
				searchTerm:'',
				searched: false,

				tagSuggestions: [],
				tagSearchDebounce: null,

				extraMenuHover: false,
			}
		},
		beforeCreate: function(){
		},
		beforeMount(){
			//search clear 
			this.$bus.$on('reset_fast_filters', () => {
				this.searchTerm = ''
				this.tagSuggestions = []
			})
		},
		beforeDestroy(){
			this.$bus.$off('reset_fast_filters')
		},
		mounted: function(){

		},
		methods: {
			tagClick(tagId){

				this.$emit('tagClick', tagId)
				this.tagSuggestions = []
				this.searchTerm = ''

			},
			clear(){
				this.searched = false
				this.searchTerm = ''
				this.tagSuggestions = []
				if(!this.$store.getters.getIsUserOnMobile){
					this.$refs.desktopSearch.focus()
				}
				this.$bus.$emit('note_reload')
			},
			pushToQuickNote(){

				const text = this.searchTerm
				this.searchTerm = ''
				this.tagSuggestions = []

				axios.post('/api/quick-note/update', { 'pushText':text.trim() } )
				.then( response => {

					this.$bus.$emit('notification', 'Saved To Scratch Pad')
				})
				.catch(error => { this.$bus.$emit('notification', 'Failed to Update The Scratch Pad') })
			},
			pushToNewNote(){

				const text = this.searchTerm
				this.searchTerm = ''
				this.tagSuggestions = []

				axios.post('/api/note/create', { text })
				.then(response => {

					if(response.data && response.data.id){
						this.$router.push('/notes/open/'+response.data.id)
					}
				})
				.catch(error => { this.$bus.$emit('notification', 'Failed to create note') })
			},
			onKeyUp(event){
				//Search Tags
				const postData = {
					'tagText':this.searchTerm.trim()
				}

				clearTimeout(this.tagSearchDebounce)

				// if(this.searchTerm.length == 0){
				// 	this.tagSuggestions = []
				// 	return
				// }

				// this.tagSearchDebounce = setTimeout(() => {
				// 	this.tagSuggestions = []
				// 	axios.post('/api/tag/suggest', postData)
				// 	.then( response => {

				// 		this.tagSuggestions = response.data
				// 	})
				// 	.catch(error => { this.$bus.$emit('notification', 'Failed to Get Suggested Tags') })
				// }, 800)
			},
			onKeyDown(event){

				//Tab
				if(event.keyCode == 9){
					this.pushToNewNote()
					event.preventDefault()
					return false
				}

      			//Commant + Enter
				if((event.metaKey || event.ctrlKey) && event.keyCode == 13 ){
					this.pushToQuickNote()
					event.preventDefault()
					return false
				}

				//Enter
				if(event.keyCode == 13){
					this.search()
					event.preventDefault()
					return false
				}
			},
			search(){

				if(this.searchTerm.length == 0){
					this.clear()
					return
				}

				this.searched = true

				this.$refs.desktopSearch.focus()
				//Blur after search on mobile
				if(this.$store.getters.getIsUserOnMobile){
					this.$refs.desktopSearch.blur()
				}

				this.$bus.$emit('update_search_term', this.searchTerm)
			},
		}
	}
</script>