<template>
	<svg
	   xmlns:dc="http://purl.org/dc/elements/1.1/"
	   xmlns:cc="http://creativecommons.org/ns#"
	   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
	   xmlns:svg="http://www.w3.org/2000/svg"
	   xmlns="http://www.w3.org/2000/svg"
	   id="svg8"
	   version="1.1"
	   viewBox="0 0 132.29166 132.29167"
	   height="500"
	   width="500">
	  <defs
	     id="defs2" />
	  <metadata
	     id="metadata5">
	    <rdf:RDF>
	      <cc:Work
	         rdf:about="">
	        <dc:format>image/svg+xml</dc:format>
	        <dc:type
	           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
	        <dc:title></dc:title>
	      </cc:Work>
	    </rdf:RDF>
	  </metadata>
	  <g
	     style="display:inline"
	     transform="translate(0,-164.70832)"
	     id="layer1">
	    <path
	       class="darken-accent"
	       id="path3813-4"
	       d="m 56.22733,165.36641 -55.56249926,15.875 8e-7,63.5 47.62499846,11.90625 v 27.78125 l -47.76066333,-13.9757 0.13566407,10.00695 55.56249926,15.875 v -47.625 l -47.6249985,-11.90625 -8e-7,-47.625 47.7606633,-13.94121 c 0.135664,-2.30629 -0.135664,-9.87129 -0.135664,-9.87129 z"
	       :style="`fill:${displayColor};fill-opacity:1;stroke:${strokeColor};stroke-width:${strokeWidth};stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`" />
	    <path
	       class="brighten-accent"
	       id="path4563"
	       d="m 20.508581,220.92891 c 15.265814,-14.23899 27.809717,-7.68002 39.687499,3.96875 v -7.9375 C 51.75093,200.8366 37.512584,206.01499 20.508581,205.05391 Z"
	       :style="`fill:${displayColor};fill-opacity:1;stroke:${strokeColor};stroke-width:${strokeWidth}px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1`" />
	    <path
	       class="brighten-accent"
	       id="path4563-6"
	       d="m 111.78985,220.92891 c -15.265834,-14.23899 -27.809737,-7.68002 -39.68752,3.96875 v -7.9375 c 8.445151,-16.12356 22.683497,-10.94517 39.68752,-11.90625 z"
	       :style="`display:inline;fill:${displayColor};fill-opacity:1;stroke:${strokeColor};stroke-width:${strokeWidth}px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1`" />
	    <path
	       class="brighten-accent"
	       id="path3813-4-2"
	       d="m 76.07108,165.36641 55.5625,15.875 v 63.5 l -47.625,11.90625 v 27.78125 l 47.76067,-13.9757 -0.13567,10.00695 -55.5625,15.875 v -47.625 l 47.625,-11.90626 V 189.17891 L 75.93542,175.2377 c -0.13567,-2.30629 0.13566,-9.87129 0.13566,-9.87129 z"
	       :style="`display:inline;fill:${displayColor};fill-opacity:1;stroke:${strokeColor};stroke-width:${strokeWidth};stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`" />
	  </g>
	</svg>
</template>

<script>

	export default {
		name: 'LoadingIcon',
		props:[ 
			'color', // hex value for setting colorr
			'stroke' // enable or disable stroke
		],
		data(){ 
			return {
				displayColor: '#21BA45', //Default green color
				strokeWidth: '0.5',
				strokeColor: 'none',
			}
		},
		beforeCreate(){

			
		},
		created(){

			if(this.stroke){
				this.strokeWidth = 0.4
				this.strokeColor = 'rgba(0,0,0,0.9)'
			}
			
			//Set color if passed
			if(this.color){
				this.displayColor = this.color
			}
		},
	}
</script>
<style type="text/css" scoped>
	.darken-accent {
		filter: brightness(62%);
		-webkit-filter: brightness(62%);
	}
	.brighten-accent {
		filter: saturate(145%);
		-webkit-filter: saturate(145%);
	}
	g > path {
		filter: drop-shadow(1px 1px 1px black);
	}
</style>