import Vue from 'vue'
import Router from 'vue-router'

//Breaking components into function sections allows webpack to load them dynamically

const HomePage = () => import(/* webpackChunkName: "HomePage" */ '@/pages/HomePage')
const LoginPage = () => import(/* webpackChunkName: "LoginPage" */ '@/pages/LoginPage')
const HelpPage = () => import(/* webpackChunkName: "HelpPage" */ '@/pages/HelpPage')
const TermsPage = () => import(/* webpackChunkName: "TermsPage" */ '@/pages/TermsPage')
const SettingsPage = () => import(/* webpackChunkName: "SettingsPage" */ '@/pages/SettingsPage')
const SharePage = () => import(/* webpackChunkName: "SharePage" */ '@/pages/SharePage')
const NotesPage = () => import(/* webpackChunkName: "NotesPage" */ '@/pages/NotesPage')
const QuickPage = () => import(/* webpackChunkName: "QuickPage" */ '@/pages/QuickPage')
const AttachmentsPage = () => import(/* webpackChunkName: "AttachmentsPage" */ '@/pages/AttachmentsPage')
const OverviewPage = () => import(/* webpackChunkName: "OverviewPage" */ '@/pages/OverviewPage')
const BookmarkletPage = () => import(/* webpackChunkName: "BookmarkletPage" */ '@/pages/BookmarkletPage')
const NotFoundPage = () => import(/* webpackChunkName: "404Page" */ '@/pages/NotFoundPage')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomePage',
      meta: {title:'Home'},
      component: HomePage
    },
    {
      path: '/login',
      name: 'LoginPage',
      meta: {title:'Login'},
      component: LoginPage
    },
    {
      path: '/notes',
      name: 'Note Page', //don't change this
      meta: {title:'Notes'},
      component: NotesPage
    },
    {
      path: '/notes/open/:id',
      name: 'Open Note',
      meta: {title: 'Open Note'},
      component: NotesPage,
    },
    {
      path: '/search/tags/:tag',
      name: 'Search Notes',
      meta: {title: 'Search Notes'},
      component: NotesPage,
    },
    {
      path: '/notes/open/:id/menu/:openMenu',
      name: 'Open Note Menu',
      meta: {title: 'Open Note Menu'},
      component: NotesPage,
    },
    {
      path: '/help',
      name: 'Help',
      meta: {title:'Help'},
      component: HelpPage
    },
    {
      path: '/terms',
      name: 'Terms',
      meta: {title:'Terms'},
      component: TermsPage
    },
    {
      path: '/bookmarklet',
      name: 'Bookmarklet',
      meta: {title:'Bookmarklet'},
      component: BookmarkletPage
    },
    {
      path: '/settings',
      name: 'Settings',
      meta: {title:'Settings'},
      component: SettingsPage
    },
    {
      path: '/public/note/:id/:token',
      name: 'Share',
      meta: {title:'Shared'},
      component: SharePage
    },
    {
      path: '/quick',
      name: 'Quick',
      meta: {title:'Scratch Pad'},
      component: QuickPage
    },
    {
      path: '/attachments',
      name: 'Attachments',
      meta: {title:'Attachments'},
      component: AttachmentsPage
    },
    {
      path: '/attachments/note/:id',
      name: 'Attachments for Note',
      meta: {title:'Attachments for Note'},
      component: AttachmentsPage
    },
    {
      path: '/attachments/type/:type',
      name: 'Attachments by Type',
      meta: {title:'Attachments by Type'},
      component: AttachmentsPage
    },
    {
      path: '/overview',
      name: 'Overview of Notes',
      meta: {title:'Overview of Notes'},
      component: OverviewPage
    },
    {
      path: '*',
      name: 'Page Not Found',
      meta: {title:'404 Page Not Found'},
      component: NotFoundPage
    },
    // Cycle Tracking
    {
      path: '/metrictrack',
      name: 'Metric Tracking',
      meta: {title:'Metric Tracking'},
      component: () => import(/* webpackChunkName: "MetrictrackingPage" */ '@/pages/MetrictrackingPage')
    },
  ]
})
