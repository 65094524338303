<style type="text/css" scoped>

	.numtainer {
		height: 1.1em;
		font-size: 1em;
		overflow: hidden;

		display: inline-block;
		box-sizing: border-box;
	}
	
	.start-high {
		color: #4dc86a;
		animation: startHigh 0.5s forwards;
	}

	.start-low {
		color: #4dc86a;
		animation: startLow 0.5s forwards;
	}

	@keyframes startLow {
		0% {
			margin-top: 0;
		}
		100% {
			margin-top: -1.2em;
		}
	}

	@keyframes startHigh {
		0% {
			margin-top: -1.2em;
		}
		100% {
			margin-top: 0;
		}
	}

</style>

<template>
	<div class="numtainer">

		<div v-if="animateUp">
			<div class="start-high">{{ newNumber }}</div>
			<div>{{ oldNumber }}</div>
		</div>

		<div v-if="animateDown">
			<div class="start-low">{{ oldNumber }}</div>
			<div>{{ newNumber }}</div>
		</div>


		<div v-if="totals">{{ totals[numberId] }}</div>
	</div>
</template>

<script>

	import { mapGetters } from 'vuex'

	export default {
		name: 'AnimatedCounterComponent',
		props: [ 'numberId' ],
		data () {
			return {
				oldNumber: 100,
				newNumber: 99,
				animateUp: false,
				animateDown: false,
			}
		},
		computed: {
			...mapGetters(['totals'])
		},
		watch:{
			totals(newVal, oldVal){
				if(oldVal && newVal && newVal[this.numberId] != oldVal[this.numberId]){

					// console.log('New number ', newVal[this.numberId])

					this.oldNumber = oldVal[this.numberId]
					this.newNumber = newVal[this.numberId]

					if(this.oldNumber > this.newNumber){
						this.animateDown = true
					} else {
						this.animateUp = true
					}
					
					setTimeout( () => {
						this.animateUp = false
						this.animateDown = false
					}, 550)

				}
			}
		},
		beforeMount(){
			
		},
		mounted(){
		},
		methods: {
			onFileClick(file){

			},
		}
	}
</script>
